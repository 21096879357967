.container {
  max-width: 1080px;
  margin: 20px auto;
}

.no-city-selected {
  font-size: 24px;
  color: #333333;
  text-align: center;
  margin-top: 40px;
}